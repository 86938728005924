<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('pump_install.pump_operator_renew_application') }} </h4>
      </template>
      <template v-slot:body>
          <form-search :data="searchAttributes" @returned-data="searchData" @change="setData"/>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_operator_renew_application') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                     <template v-slot:cell(address)="data">
                     {{ getAddress(data.item) }}
                    </template>
                     <template v-slot:cell(pump_address)="data">
                     {{ getPumpAddress(data.item) }}
                    </template>
                    <template v-slot:cell(attached_document_link)="data">
                      <a download target="_blank" :href="irriUrl+data.item.general_minutes" variant=" iq-bg-success mr-1 mb-1" size="sm" title="Download" @click="download(data.item)"><i class="ri-download-cloud-fill"></i></a><br>
                    </template>
                    <template v-slot:cell(payment_status)="data">
                     {{ getPaymentStatus(data.item.payment_status) }}
                    </template>
                    <template v-slot:cell(status)="data">
                     {{ getStatus(data.item.status) }}
                    </template>
                    <template v-slot:cell(created_date)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascritp:" class="btn_table_action table_action_status" v-b-modal.modal-7 title="View Application"  @click="setRowId(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascritp:" class="btn_table_action table_action_view" v-if="data.item.status === 2" title="Approve" @click="approve(data.item)"><i class="fas fa-check"></i></a>
                      <a href="javascritp:" class="btn_table_action table_action_toggle" v-if="data.item.status === 2" v-b-modal.modal-1 title="Reject" @click="setRowId(data.item)"><i class="ri-close-line"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <PumpOptApplicationDetails :id="testId" :key="testId"/>
          </p>
        </b-modal>
        <b-modal id="modal-1" size="lg" :title="$t('externalUserIrrigation.rejected') + ' ' + $t('globalTrans.submit')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <Reject :id="testId" :key="testId"/>
          </p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptRenewApplicationList, pumpOptApplicationApprove } from '../../api/routes'
import PumpOptApplicationDetails from '../PumpOptApplicationDetails'
import Reject from './Reject'
import FormSearch from '@/components/structure/form/search/FormSearch'
import { Address } from '@/mixins/helper-functions'
import { mapGetters } from 'vuex'

export default {
  mixins: [Address],
  name: 'UiDataTable',
  components: {
    PumpOptApplicationDetails,
    Reject,
    FormSearch
  },
  data () {
    return {
        statePrefix: 'commonObj', // Required to get store sates for addresses
        pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      applications: [],
      loggedInUserId: this.$store.state.Auth.authUser.user_id,
      search: {
          mouza_no: '',
          plot_no: '',
          jl_no: '',
          org_id: 0,
          far_division_id: 0,
          far_district_id: 0,
          far_upazilla_id: 0,
          far_union_id: 0
      },
      testId: 0,
      rows: [],
      ifApply: false,
      irriUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    searchAttributes () {
      return {
          buttonText: this.$t('globalTrans.search'),
          data: [
              {
                name: 'org_id',
                type: 'Select',
                label: 'pump_install.organization',
                options: this.orgList,
                value: this.search.org_id
              },
              {
                name: 'far_division_id',
                type: 'Select',
                label: 'org_pro_division.division',
                options: this.divisionList,
                value: this.search.far_division_id
              },
              {
                  name: 'far_district_id',
                  type: 'Select',
                  label: 'org_pro_district.district',
                  options: this.districtList,
                  value: this.search.far_district_id
              },
              {
                  name: 'far_upazilla_id',
                  type: 'Select',
                  label: 'org_pro_upazilla.upazilla',
                  options: this.upazilaList,
                  value: this.search.far_upazilla_id
              },
              {
                  name: 'far_union_id',
                  type: 'Select',
                  label: 'org_pro_union.union',
                  options: this.unionList
              },
              {
                  name: 'mouza_no',
                  type: 'Input',
                  label: 'pump_install.mauza_no',
                  labelCol: 3
              },
              {
                  name: 'plot_no',
                  type: 'Input',
                  label: 'pump_install.plot_no',
                  labelCol: 3
              },
              {
                  name: 'jl_no',
                  type: 'Input',
                  label: 'pump_install.pump_jl_no',
                  labelCol: 3
              }
          ]
      }
    },
    formTitle () {
      return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.ExternalUserIrrigation.pumpOptApplicationLists
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('pump_install.name'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.address'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.pump_address'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.payment_status'), class: 'text-left' },
          { label: this.$t('pump_install.created_date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
        { key: 'index' },
          { key: 'name_bn' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
         { key: 'index' },
          { key: 'name' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
      'search.far_division_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.districtList = this.getDistrictList(newVal)
          }
      },
      'search.far_district_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.upazilaList = this.getUpazilaList(newVal)
          }
      },
      'search.far_upazilla_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.unionList = this.getUnionList(newVal)
          }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.far_division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.far_district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.far_upazilla_id) {
              this.unionList = this.getUnionList()
            }
        }
    }
  },
  created () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  mounted () {
    core.index()
    this.loadData()
  },
  methods: {
    setData (item) {
      Object.assign(this.search, item)
    },
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    async searchData (payload) {
      this.search = payload
      await this.loadData()
    },
    setRowId (item) {
      this.testId = item.id
    },
    approve (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${pumpOptApplicationApprove}/${item.id}`)
      .then(response => {
        if (response.success) {
          this.$store.dispatch('Complain/updateComplainChainStatus', item)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, pumpOptRenewApplicationList, params)
      .then(response => {
        if (response.success) {
          this.applications = response.data.data
          this.paginationData(response.data)
        } else {
          this.applications = []
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getPaymentStatus (payStatus) {
      if (payStatus === 0) {
        return this.$t('externalUserIrrigation.unpaid')
      } else {
        return this.$t('pump_install.paid')
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.approve')
      } else if (status === 4) {
        return this.$t('pump_install.reject')
      } else {
        return this.$t('pump_install.pending')
      }
    },
    formatList (data) {
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList

      const division = divisionList.find(division => division.value === data.far_division_id)
      const district = districtList.find(district => district.value === data.far_district_id)
      const upazilla = upazilaList.find(upazilla => upazilla.value === data.far_upazilla_id)
      const union = unionList.find(union => union.value === data.far_union_id)
      return Object.assign({}, data,
        { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' },
        { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' },
        { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' },
        { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
      )
    },
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    }
  }
}
</script>
